button, input {
    border-radius: 8px;
    box-sizing: border-box;
    display: block;
    font-size: 16px;
    margin: 2px 0;
    padding: 5px;
    width: 100%;
}

input {
    border: 1px solid black;
}

button {
    border: 1px solid black;
    cursor: pointer;
}

input, textarea {
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  border: 2px solid black;
  margin-bottom: 16px;
}

#add-comment-form {
  border-bottom: 2px solid black;
  padding: 16px;
}

#add-comment-form h3 {
  margin-bottom: 16px;
}

#add-comment-form button {
  width: 320px;
}

/* Selector switch customization */
.form-switch .form-check-input:checked[type=checkbox]:after {
  margin-top: -10px;
}

.form-switch .form-check-input[type=checkbox]:after {
  margin-top: -10px;
  margin-left: -10px;
}