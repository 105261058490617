/* Start: Cards */
.content-cards {
    display: flex;
  }
  
  .card {
      background: white;
      margin-bottom: 2em;	
  }
  
  .card a {
      color: black;
      text-decoration: none;
  }
  
  .card a:hover {
      box-shadow: 3px 3px 8px hsl(0, 0%, 70%);
  }
  
  .card-content {
      padding: 1.4em;
  }
  
  .card-content h2 {
      margin-top: 0;
      margin-bottom: .5em;
      font-weight: normal;
  }
  
  .card-content p {
      font-size: 95%;
  }
  
  /* Flexbox stuff */
  
  @media screen and (min-width: 40em) {
      .cards {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: -1em;
      }
  
      .card {
          display: flex;
          flex: 0 1 calc(50% - .5em);
          margin-bottom: 1em;
      }
  }
  
  @media screen and (min-width: 60em) {
      .cards {
          margin-top: inherit;
      }
      
      .card {
          flex: 0 1 calc(33% - 1em);
          margin-bottom: 2em;
      }
  }
  /* End: Cards */