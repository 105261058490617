  .success {
    background-color: #90ee90;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 16px;
    text-align: center;
  }

  .fail {
    background-color: #ffcccb;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 16px;
    text-align: center;
  }

  .error {
    padding: 8px;
    border-radius: 4px;
    background-color: pink;
  }