@import url('css/card-styles.css');
@import url('css/devices.css');
/* @import url('css/nav.css'); */
@import url('css/reactive-btn.css');
@import url('css/alerts.css');
@import url('css/forms.css');
@import url('css/events.css');
@import url('css/comments.css');
@import url('css/user-actions.css');
@import url('css/typography.css');
@import url('css/image-uploader.css');

/* CSS Spinner */
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #111958;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
/* End CSS Spinner */

body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

.content-container {
  background-color: white;
  border-radius: 8px;
  min-width: 300px;
  padding: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
}

/* Flexbox stuff */
@media screen and (min-width: 50em) {
    
  .outer-wrap {
      display: flex;
      flex-direction: column;
  }
  
  .main-area {
      flex: 1 1 auto;
      order: 1;
  }
  
  .post-content {
      max-width: 40em;
      margin: 0 auto;
  }

}

@media screen and (max-width: 25em) {
  /* User Profile */
  .profile-photo {
      margin-top: -160px !important;
  }
}

@media screen and (min-width: 60em) {
}

hr {
    margin: 15px 0;
}

