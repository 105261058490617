  .dropbox {
    text-align: center;
    padding: 5px;
    width: 100%;
    margin: auto;
    margin-top: 5px;
  }

  .containerImg {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px auto;
    padding: 10px;
  }

  .containerImg img {
    height: 200px;
    width: 200px;
    margin-right: 15px;
  }

  .btn {
    padding: 15px;
    background-color: #de1a1a;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }

  .btn:hover{
    background-color: #945c5c;
  }